<template>
    <ExpansionPanelContainer class="py-0" :panelsModel="isOpen ? [0] : []">
        <ExpansionPanel
            :title="$t('evaluate.titles.readingErrors')"
            :titleIcon="icon"
            :iconColor="iconColor"
        >
            <div class="my-2" v-for="error in errors" :key="error.id">
                <ErrorBannerCard
                    :errorType="$t(`evaluate.evaluations.errorsTypes.${error.errorType}`)"
                    :amount="getAmountOferrors(error.errorType)"
                    :color="getErrorTypeColor(error.errorType)"
                />
            </div>
            <v-card :color="`primary`" elevation="0" rounded="lg" class="pa-0 ma-0">
                <v-row align-content="center" justify="space-between" class="ma-0 pa-2 px-6">
                    <p class="white--text body-2 ma-0 pa-0 font-weight-semibold mt-2">
                        {{$t('evaluate.tables.diagnosisResults.labels.totalErrors')}}
                    </p>

                    <v-chip label class="pa-3 ml-3 px-6" :color="`white`">
                        <span :class="`body-2 black-grey--text font-weight-bold`">
                            {{ getTotalErrors() }}
                        </span>
                    </v-chip>
                </v-row>
            </v-card>
        </ExpansionPanel>
    </ExpansionPanelContainer>
</template>

<script>
import { mapState } from 'vuex'
import ExpansionPanelContainer from '@/components/core/expansionPanels/container/index.vue'
import ExpansionPanel from '@/components/core/expansionPanels/panel/index.vue'
import ErrorBannerCard from '../../banners/errorCard/index.vue'

export default {
    name: 'DiagnosisErrors',
    components: {
        ExpansionPanelContainer,
        ExpansionPanel,
        ErrorBannerCard,
    },
    props: {
        errorsData: {
            type: Array,
            required: true,
            default: () => [],
        },
        isOpen: {
            type: Boolean,
            default: false,
            required: false,
        },
        icon: {
            type: String,
            default: null,
            required: false,
        },
        iconColor: {
            type: String,
            default: 'primary',
            required: false,
        },
    },
    data() {
        return {}
    },
    computed: {
        ...mapState({
            errors: state => state.fluency.errorsType,
        }),
    },
    methods: {
        getErrorTypeColor(errorType) {
            switch (errorType) {
                case 'INSERTION':
                    return {
                        card: 'rgba(255, 230, 224, 0.3)',
                        chip: 'rgba(255, 230, 224, 1)',
                        text: '#C46D58',
                    }
                case 'SUBSTITUTION':
                    return {
                        card: 'rgba(254, 223, 239, 0.3)',
                        chip: 'rgba(254, 223, 239, 1)',
                        text: '#AF3286',
                    }
                case 'OMISSION':
                    return {
                        card: 'rgba(238, 210, 248, 0.3)',
                        chip: 'rgba(238, 210, 248, 1)',
                        text: '#9E2BBE',
                    }
                case 'REPETITION':
                    return {
                        card: 'rgba(207, 232, 250, 0.3)',
                        chip: 'rgba(207, 232, 250, 1)',
                        text: '#099ADB',
                    }
                case 'DICTION_ERROR':
                    return {
                        card: 'rgba(214, 219, 239, 0.3)',
                        chip: 'rgba(214, 219, 239, 1)',
                        text: '#4F48E4',
                    }
            }
        },
        getAmountOferrors(errorType) {
            return this.errorsData.filter((error) => error.category.toUpperCase() === errorType)
                .length
        },
        getTotalErrors() {
            return this.errorsData.length
        }
    },
}
</script>
