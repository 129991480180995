var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"grey lighten-1","outlined":"","rounded":"","elevation":"0","small":""},on:{"click":_vm.handleGoBackToAssignedEvaluations}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"grey lighten-1","small":""}},[_vm._v("mdi-arrow-left")]),_c('span',{staticClass:"grey--text font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t('library.goBack'))+" ")])],1),_c('v-card',{staticClass:"w-100 py-6 my-5",attrs:{"rounded":"lg","color":"white","elevation":"0"}},[_c('section',{staticClass:"pa-6"},[_c('EvaluatePanel',{attrs:{"diagnosis-data":_vm.studentEvaluation,"reading-category":_vm.studentEvaluationReadingCategory},on:{"on-student-change":_vm.handleStudentChange}})],1)]),_c('v-card',{staticClass:"w-100 py-6 my-5",attrs:{"rounded":"lg","color":"transparent","elevation":"0"}},[_c('v-row',{staticClass:"pa-3",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{staticClass:"my-3 pa-0 pr-3",attrs:{"cols":"12","md":"7"}},[_c('v-card',{staticClass:"ma-0 pa-0",attrs:{"elevation":"0","rounded":"lg"}},[_c('v-row',{staticClass:"w-100 d-flex flex-column flex-md-row",attrs:{"align-content":"center","justify":"start","justify-md":"space-between"}},[_c('h3',{staticClass:"px-6 py-1 py-md-6 d-flex align-start justify-content-start text-center"},[_vm._v(" Lectura asignada ")]),(
                                _vm.studentEvaluation &&
                                _vm.originalEvaluationResult?.id &&
                                !_vm.loading &&
                                _vm.isEvalutionWithChanges
                            )?_c('div',{staticClass:"px-6 py-3 pr-0 d-flex align-center justify-content-center text-center"},[_c('v-chip',{attrs:{"label":"","small":"","color":"amber lighten-5"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"orange"}},[_vm._v("mdi-information")]),_c('span',{staticClass:"orange--text darken-5 body-2 mx-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('evaluate.messages.unsavedChanges'))+" ")])],1)],1):_vm._e()]),_c('v-divider'),(_vm.currentText)?_c('article',{staticClass:"pa-6"},[_c('div',[_c('h2',{staticClass:"bee-registered-title"},_vm._l((_vm.textSplittedWords.slice(
                                        0,
                                        _vm.currentText.title_words_amount
                                    )),function(word,i){return _c('span',{key:i,class:`bee-text-word ${
                                        _vm.isWordError(i)
                                            ? `${_vm.getErrorTypeColor(
                                                  _vm.isWordError(i).category
                                              )} mx-1 rounded-lg px-2 text-center`
                                            : ''
                                    } ${
                                        _vm.wordSelected?.index === i
                                            ? 'bee-text-word--selected'
                                            : ''
                                    }`,style:(`${
                                        _vm.isWordError(i)
                                            ? `background-color: ${_vm.getErrorTypeColor(
                                                  _vm.isWordError(i).category
                                              )}`
                                            : 'background-color: inherit;'
                                    }`)},[_c('span',{class:_vm.lastWordFromAudio &&
                                            _vm.lastWordFromAudio.position === i
                                                ? 'blue-grey lighten-4 rounded-lg px-2'
                                                : '',on:{"click":(ev) => _vm.handleWordSelected(word, i, ev)}},[_vm._v(" "+_vm._s(word)+" ")]),(_vm.wordSelected != null && _vm.wordSelected.index === i)?_c('article',{staticClass:"absolute w-auto bee-register-word-panel"},[(_vm.lastWordFromAudio.position === i)?_c('v-chip',{attrs:{"color":"blue-grey lighten-4 d-block w-50 text-center my-1 pa-0","x-small":""}},[_vm._v(" Última palabra ")]):_vm._e(),_c('v-btn',{staticClass:"position-absolute bee-register-word-panel__close-button",attrs:{"icon":"","small":"","color":"black"},on:{"click":_vm.closeWordSelected}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('strong',{staticClass:"body-2 font-weight-bold"},[_vm._v(" Marcar palabra como: ")]),_vm._l((_vm.errorsType),function(error){return _c('v-checkbox',{key:error.errorType,staticClass:"ma-0",style:(`${
                                                _vm.wordSelectedError === error.errorType
                                                    ? `background-color: ${_vm.getErrorTypeColor(
                                                          error.errorType
                                                      )}`
                                                    : ''
                                            } ${
                                                _vm.wordSelectedError === error.errorType
                                                    ? `background-color: ${_vm.getErrorTypeColor(
                                                          error.errorType
                                                      )}`
                                                    : ''
                                            }`),attrs:{"dense":"","color":_vm.getCheckboxErrorTypeColor(error.errorType),"label":_vm.$t(
                                                    `evaluate.evaluations.errorsTypes.${error.errorType}`
                                                ),"value":error.errorType,"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:`body-2 font-weight-light ${
                                                        _vm.wordSelectedError === error.errorType &&
                                                        _vm.getErrorTypeColor(error.errorType)
                                                    } lighten-5 px-1 `},[_vm._v(" "+_vm._s(_vm.$t( `evaluate.evaluations.errorsTypes.${error.errorType}` ))+" ")]),(
                                                        _vm.isWordError(
                                                            i
                                                        )?.category !== error.errorType
                                                    )?_c('div',{staticClass:"bee-checkbox-test",style:(`${`background-color: ${_vm.getCheckboxErrorTypeColor(
                                                        error.errorType
                                                    )}`}`)}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.wordSelectedError),callback:function ($$v) {_vm.wordSelectedError=$$v},expression:"wordSelectedError"}})}),(_vm.lastWordFromAudio.position !== i)?_c('v-checkbox',{staticClass:"ma-0",attrs:{"dense":"","label":"Última palabra","value":i,"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:`body-2 font-weight-light ${
                                                        _vm.lastWordFromAudio.position === i
                                                            ? 'blue-grey lighten-4'
                                                            : ''
                                                    } px-1 `},[_vm._v(" Última palabra ")]),_c('div',{staticClass:"bee-checkbox-test",style:('background-color: #4B5768;')})]},proxy:true}],null,true),model:{value:(_vm.lastWordFromAudio.position),callback:function ($$v) {_vm.$set(_vm.lastWordFromAudio, "position", $$v)},expression:"lastWordFromAudio.position"}}):_vm._e()],2):_vm._e()])}),0),_c('v-divider',{staticClass:"my-2"}),_c('p',{staticClass:"bee-registered-text"},_vm._l((_vm.textSplittedWords.slice(
                                        _vm.currentText.title_words_amount,
                                        _vm.textSplittedWords.length
                                    )),function(word,i){return _c('span',{key:i,class:`relative bee-text-word  ${
                                        _vm.isWordError(i + _vm.currentText.title_words_amount)
                                            ? `${_vm.getErrorTypeColor(
                                                  _vm.isWordError(
                                                      i + _vm.currentText.title_words_amount
                                                  ).category
                                              )} lighten-4 mx-1 rounded-lg px-2 text-center`
                                            : ''
                                    } ${
                                        _vm.wordSelected?.index ===
                                        i + _vm.currentText.title_words_amount
                                            ? 'bee-text-word--selected'
                                            : ''
                                    }`,style:(`${
                                        _vm.isWordError(i + _vm.currentText.title_words_amount)
                                            ? `background-color: ${_vm.getErrorTypeColor(
                                                  _vm.isWordError(
                                                      i + _vm.currentText.title_words_amount
                                                  ).category
                                              )}`
                                            : 'background-color: inherit;'
                                    }`)},[_c('span',{class:_vm.lastWordFromAudio &&
                                            _vm.lastWordFromAudio.position ===
                                                i + _vm.currentText.title_words_amount
                                                ? 'blue-grey lighten-4 rounded-lg px-2 bee-text-word--last'
                                                : '',on:{"click":(ev) =>
                                                _vm.handleWordSelected(
                                                    word,
                                                    i + _vm.currentText.title_words_amount,
                                                    ev
                                                )}},[_vm._v(" "+_vm._s(word)+" ")]),(i !== _vm.textSplittedWords.length - 1)?_c('span'):_vm._e(),(
                                            _vm.wordSelected !== null &&
                                            _vm.wordSelected?.index ===
                                                i + _vm.currentText.title_words_amount
                                        )?_c('article',{staticClass:"bee-register-word-panel"},[(
                                                _vm.lastWordFromAudio.position ===
                                                i + _vm.currentText.title_words_amount
                                            )?_c('v-chip',{attrs:{"color":"blue-grey lighten-4 d-block w-50 text-center my-1 pa-0","x-small":""}},[_vm._v(" Última palabra ")]):_vm._e(),_c('v-btn',{staticClass:"position-absolute bee-register-word-panel__close-button",attrs:{"icon":"","small":"","color":"black"},on:{"click":_vm.closeWordSelected}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('strong',{staticClass:"body-2 font-weight-bold"},[_vm._v(" Marcar palabra como: ")]),_vm._l((_vm.errorsType),function(error){return _c('v-checkbox',{key:error.errorType,staticClass:"ma-0",style:(`${
                                                _vm.wordSelectedError === error.errorType
                                                    ? `background-color: ${_vm.getErrorTypeColor(
                                                          error.errorType
                                                      )}`
                                                    : ''
                                            }`),attrs:{"dense":"","color":_vm.getCheckboxErrorTypeColor(error.errorType),"label":_vm.$t(
                                                    `evaluate.evaluations.errorsTypes.${error.errorType}`
                                                ),"value":error.errorType,"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:`body-2 font-weight-light ${
                                                        _vm.wordSelectedError === error.errorType &&
                                                        _vm.getErrorTypeColor(error.errorType)
                                                    } px-1 `,style:(`${
                                                        _vm.wordSelectedError === error.errorType
                                                            ? `background-color: ${_vm.getErrorTypeColor(
                                                                  error.errorType
                                                              )}`
                                                            : ''
                                                    }`)},[_vm._v(" "+_vm._s(_vm.$t( `evaluate.evaluations.errorsTypes.${error.errorType}` ))+" ")]),(
                                                        _vm.isWordError(
                                                            i + _vm.currentText.title_words_amount
                                                        )?.category !== error.errorType
                                                    )?_c('div',{staticClass:"bee-checkbox-test",style:(`${`background-color: ${_vm.getCheckboxErrorTypeColor(
                                                        error.errorType
                                                    )}`}`)}):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.wordSelectedError),callback:function ($$v) {_vm.wordSelectedError=$$v},expression:"wordSelectedError"}})}),(
                                                _vm.lastWordFromAudio.position !==
                                                i + _vm.currentText.title_words_amount
                                            )?_c('v-checkbox',{staticClass:"ma-0",attrs:{"dense":"","label":"Última palabra","value":i + _vm.currentText.title_words_amount,"hide-details":"","color":"blue-grey lighten-4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:`body-2 font-weight-light ${
                                                        _vm.lastWordFromAudio.position ===
                                                        i + _vm.currentText.title_words_amount
                                                            ? 'amber lighten-5'
                                                            : ''
                                                    } px-1 `},[_vm._v(" Última palabra ")]),_c('div',{staticClass:"bee-checkbox-test",style:('background-color: #4B5768;')})]},proxy:true}],null,true),model:{value:(_vm.lastWordFromAudio.position),callback:function ($$v) {_vm.$set(_vm.lastWordFromAudio, "position", $$v)},expression:"lastWordFromAudio.position"}}):_vm._e()],2):_vm._e()])}),0)],1)]):_vm._e(),_c('v-divider'),(_vm.currentText)?_c('p',{staticClass:"body-2 mx-6 py-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('evaluate.utils.textWordsNumber'))+":")]),_vm._v(" "+_vm._s(_vm.currentText.word_count)+" ")]):_vm._e()],1),_c('SimpleButton',{staticClass:"ma-0 my-5",attrs:{"text":'Guardar',"loading":_vm.loading,"disabled":!_vm.currentStudentDiagnosis?.recording_url},on:{"click":_vm.handleOnUpdate}})],1),_c('v-col',{staticClass:"my-0 pa-0",attrs:{"cols":"12","md":"5"}},[_c('v-col',{staticClass:"my-0 mb-3 pa-0",attrs:{"cols":"12"}},[_c('RecordedAudio',{staticClass:"ma-0 pa-0",attrs:{"audioURL":_vm.studentEvaluation?.recording_url,"lecture-time":_vm.studentEvaluation?.audio_duration,"icon":_vm.isResults ? 'mdi-check-circle' : null,"iconColor":"green","isOpen":!_vm.isResults,"loading":_vm.loading},on:{"on-audio-ready":_vm.onVoiceRecordFinish}})],1),_c('v-col',{staticClass:"ma-0 mb-3 pa-0",attrs:{"cols":"12"}},[_c('DiagnosisErrors',{attrs:{"errors-data":_vm.studentDiagnosisErrors || [],"icon":_vm.isResults ? 'mdi-check-circle' : null,"iconColor":"green"}})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('ReadingQualityPanel',{attrs:{"isOpen":false,"icon":_vm.isResults
                                ? _vm.studentEvaluation?.reading_category
                                    ? 'mdi-check-circle'
                                    : 'mdi-minus-circle'
                                : null,"iconColor":_vm.studentEvaluation?.reading_category ? 'green' : 'grey',"reading-category":_vm.studentEvaluationReadingCategory,"loading":_vm.loading},on:{"on-select-category":_vm.handleReadingCategory}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }