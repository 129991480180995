<template>
    <v-container class="ma-0 pa-0">
        <v-btn
            color="grey lighten-1"
            outlined
            rounded
            elevation="0"
            small
            class="text-capitalize"
            @click="handleGoBackToAssignedEvaluations"
        >
            <v-icon class="mr-1" color="grey lighten-1" small>mdi-arrow-left</v-icon>
            <span class="grey--text font-weight-semibold">
                {{ $t('library.goBack') }}
            </span>
        </v-btn>

        <v-card class="w-100 py-6 my-5" rounded="lg" color="white" elevation="0">
            <section class="pa-6">
                <EvaluatePanel
                    :diagnosis-data="studentEvaluation"
                    :reading-category="studentEvaluationReadingCategory"
                    @on-student-change="handleStudentChange"
                />
            </section>
        </v-card>

        <v-card class="w-100 py-6 my-5" rounded="lg" color="transparent" elevation="0">
            <v-row class="pa-3" align-content="center" justify="center">
                <!-- Student text -->
                <v-col cols="12" md="7" class="my-3 pa-0 pr-3">
                    <v-card elevation="0" rounded="lg" class="ma-0 pa-0">
                        <v-row
                            class="w-100 d-flex flex-column flex-md-row"
                            align-content="center"
                            justify="start"
                            justify-md="space-between"
                        >
                            <h3
                                class="px-6 py-1 py-md-6 d-flex align-start justify-content-start text-center"
                            >
                                Lectura asignada
                            </h3>

                            <div
                                v-if="
                                    studentEvaluation &&
                                    originalEvaluationResult?.id &&
                                    !loading &&
                                    isEvalutionWithChanges
                                "
                                class="px-6 py-3 pr-0 d-flex align-center justify-content-center text-center"
                            >
                                <v-chip label small color="amber lighten-5">
                                    <v-icon small class="mr-1" color="orange"
                                        >mdi-information</v-icon
                                    >
                                    <span
                                        class="orange--text darken-5 body-2 mx-1 font-weight-medium"
                                    >
                                        {{ $t('evaluate.messages.unsavedChanges') }}
                                    </span>
                                </v-chip>
                            </div>
                        </v-row>

                        <v-divider></v-divider>

                        <article v-if="currentText" class="pa-6">
                            <div>
                                <h2 class="bee-registered-title">
                                    <span
                                        v-for="(word, i) in textSplittedWords.slice(
                                            0,
                                            currentText.title_words_amount
                                        )"
                                        :key="i"
                                        :style="`${
                                            isWordError(i)
                                                ? `background-color: ${getErrorTypeColor(
                                                      isWordError(i).category
                                                  )}`
                                                : 'background-color: inherit;'
                                        }`"
                                        :class="`bee-text-word ${
                                            isWordError(i)
                                                ? `${getErrorTypeColor(
                                                      isWordError(i).category
                                                  )} mx-1 rounded-lg px-2 text-center`
                                                : ''
                                        } ${
                                            wordSelected?.index === i
                                                ? 'bee-text-word--selected'
                                                : ''
                                        }`"
                                    >
                                        <span
                                            :class="
                                                lastWordFromAudio &&
                                                lastWordFromAudio.position === i
                                                    ? 'blue-grey lighten-4 rounded-lg px-2'
                                                    : ''
                                            "
                                            @click="(ev) => handleWordSelected(word, i, ev)"
                                        >
                                            {{ word }}
                                        </span>
                                        <article
                                            class="absolute w-auto bee-register-word-panel"
                                            v-if="wordSelected != null && wordSelected.index === i"
                                        >
                                            <v-chip
                                                v-if="lastWordFromAudio.position === i"
                                                color="blue-grey lighten-4 d-block w-50 text-center my-1 pa-0"
                                                x-small
                                            >
                                                Última palabra
                                            </v-chip>
                                            <v-btn
                                                icon
                                                class="position-absolute bee-register-word-panel__close-button"
                                                small
                                                color="black"
                                                @click="closeWordSelected"
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            <strong class="body-2 font-weight-bold">
                                                Marcar palabra como:
                                            </strong>
                                            <v-checkbox
                                                v-model="wordSelectedError"
                                                v-for="error in errorsType"
                                                dense
                                                :key="error.errorType"
                                                :style="`${
                                                    wordSelectedError === error.errorType
                                                        ? `background-color: ${getErrorTypeColor(
                                                              error.errorType
                                                          )}`
                                                        : ''
                                                } ${
                                                    wordSelectedError === error.errorType
                                                        ? `background-color: ${getErrorTypeColor(
                                                              error.errorType
                                                          )}`
                                                        : ''
                                                }`"
                                                :color="getCheckboxErrorTypeColor(error.errorType)"
                                                :label="
                                                    $t(
                                                        `evaluate.evaluations.errorsTypes.${error.errorType}`
                                                    )
                                                "
                                                :value="error.errorType"
                                                hide-details
                                                class="ma-0"
                                            >
                                                <template v-slot:label>
                                                    <span
                                                        :class="`body-2 font-weight-light ${
                                                            wordSelectedError === error.errorType &&
                                                            getErrorTypeColor(error.errorType)
                                                        } lighten-5 px-1 `"
                                                    >
                                                        {{
                                                            $t(
                                                                `evaluate.evaluations.errorsTypes.${error.errorType}`
                                                            )
                                                        }}
                                                    </span>

                                                    <div
                                                        v-if="
                                                            isWordError(
                                                                i
                                                            )?.category !== error.errorType
                                                        "
                                                        class="bee-checkbox-test"
                                                        :style="`${`background-color: ${getCheckboxErrorTypeColor(
                                                            error.errorType
                                                        )}`}`"
                                                    ></div>
                                                </template>
                                            </v-checkbox>
                                            <v-checkbox
                                                v-if="lastWordFromAudio.position !== i"
                                                v-model="lastWordFromAudio.position"
                                                dense
                                                label="Última palabra"
                                                :value="i"
                                                hide-details
                                                class="ma-0"
                                            >
                                                <template v-slot:label>
                                                    <span
                                                        :class="`body-2 font-weight-light ${
                                                            lastWordFromAudio.position === i
                                                                ? 'blue-grey lighten-4'
                                                                : ''
                                                        } px-1 `"
                                                    >
                                                        Última palabra
                                                    </span>

                                                    <div
                                                        class="bee-checkbox-test"
                                                        :style="'background-color: #4B5768;'"
                                                    ></div>
                                                </template>
                                            </v-checkbox>
                                        </article>
                                    </span>
                                </h2>

                                <v-divider class="my-2"></v-divider>

                                <p class="bee-registered-text">
                                    <span
                                        v-for="(word, i) in textSplittedWords.slice(
                                            currentText.title_words_amount,
                                            textSplittedWords.length
                                        )"
                                        :key="i"
                                        :style="`${
                                            isWordError(i + currentText.title_words_amount)
                                                ? `background-color: ${getErrorTypeColor(
                                                      isWordError(
                                                          i + currentText.title_words_amount
                                                      ).category
                                                  )}`
                                                : 'background-color: inherit;'
                                        }`"
                                        :class="`relative bee-text-word  ${
                                            isWordError(i + currentText.title_words_amount)
                                                ? `${getErrorTypeColor(
                                                      isWordError(
                                                          i + currentText.title_words_amount
                                                      ).category
                                                  )} lighten-4 mx-1 rounded-lg px-2 text-center`
                                                : ''
                                        } ${
                                            wordSelected?.index ===
                                            i + currentText.title_words_amount
                                                ? 'bee-text-word--selected'
                                                : ''
                                        }`"
                                    >
                                        <span
                                            :class="
                                                lastWordFromAudio &&
                                                lastWordFromAudio.position ===
                                                    i + currentText.title_words_amount
                                                    ? 'blue-grey lighten-4 rounded-lg px-2 bee-text-word--last'
                                                    : ''
                                            "
                                            @click="
                                                (ev) =>
                                                    handleWordSelected(
                                                        word,
                                                        i + currentText.title_words_amount,
                                                        ev
                                                    )
                                            "
                                        >
                                            {{ word }}
                                        </span>
                                        <span v-if="i !== textSplittedWords.length - 1"></span>

                                        <article
                                            class="bee-register-word-panel"
                                            v-if="
                                                wordSelected !== null &&
                                                wordSelected?.index ===
                                                    i + currentText.title_words_amount
                                            "
                                        >
                                            <v-chip
                                                v-if="
                                                    lastWordFromAudio.position ===
                                                    i + currentText.title_words_amount
                                                "
                                                color="blue-grey lighten-4 d-block w-50 text-center my-1 pa-0"
                                                x-small
                                            >
                                                Última palabra
                                            </v-chip>

                                            <v-btn
                                                icon
                                                class="position-absolute bee-register-word-panel__close-button"
                                                small
                                                color="black"
                                                @click="closeWordSelected"
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>

                                            <strong class="body-2 font-weight-bold">
                                                Marcar palabra como:
                                            </strong>
                                            <v-checkbox
                                                v-model="wordSelectedError"
                                                v-for="error in errorsType"
                                                dense
                                                :key="error.errorType"
                                                :color="getCheckboxErrorTypeColor(error.errorType)"
                                                :label="
                                                    $t(
                                                        `evaluate.evaluations.errorsTypes.${error.errorType}`
                                                    )
                                                "
                                                :value="error.errorType"
                                                hide-details
                                                class="ma-0"
                                                :style="`${
                                                    wordSelectedError === error.errorType
                                                        ? `background-color: ${getErrorTypeColor(
                                                              error.errorType
                                                          )}`
                                                        : ''
                                                }`"
                                            >
                                                <template v-slot:label>
                                                    <span
                                                        :style="`${
                                                            wordSelectedError === error.errorType
                                                                ? `background-color: ${getErrorTypeColor(
                                                                      error.errorType
                                                                  )}`
                                                                : ''
                                                        }`"
                                                        :class="`body-2 font-weight-light ${
                                                            wordSelectedError === error.errorType &&
                                                            getErrorTypeColor(error.errorType)
                                                        } px-1 `"
                                                    >
                                                        {{
                                                            $t(
                                                                `evaluate.evaluations.errorsTypes.${error.errorType}`
                                                            )
                                                        }}
                                                    </span>

                                                    <div
                                                        v-if="
                                                            isWordError(
                                                                i + currentText.title_words_amount
                                                            )?.category !== error.errorType
                                                        "
                                                        class="bee-checkbox-test"
                                                        :style="`${`background-color: ${getCheckboxErrorTypeColor(
                                                            error.errorType
                                                        )}`}`"
                                                    ></div>
                                                </template>
                                            </v-checkbox>
                                            <v-checkbox
                                                v-if="
                                                    lastWordFromAudio.position !==
                                                    i + currentText.title_words_amount
                                                "
                                                v-model="lastWordFromAudio.position"
                                                dense
                                                label="Última palabra"
                                                :value="i + currentText.title_words_amount"
                                                hide-details
                                                color="blue-grey lighten-4"
                                                class="ma-0"
                                            >
                                                <template v-slot:label>
                                                    <span
                                                        :class="`body-2 font-weight-light ${
                                                            lastWordFromAudio.position ===
                                                            i + currentText.title_words_amount
                                                                ? 'amber lighten-5'
                                                                : ''
                                                        } px-1 `"
                                                    >
                                                        Última palabra
                                                    </span>

                                                    <div
                                                        class="bee-checkbox-test"
                                                        :style="'background-color: #4B5768;'"
                                                    ></div>
                                                </template>
                                            </v-checkbox>
                                        </article>
                                    </span>
                                </p>
                            </div>
                        </article>

                        <v-divider></v-divider>

                        <p class="body-2 mx-6 py-3" v-if="currentText">
                            <span class="font-weight-bold"
                                >{{ $t('evaluate.utils.textWordsNumber') }}:</span
                            >
                            {{ currentText.word_count }}
                        </p>
                    </v-card>

                    <SimpleButton
                        class="ma-0 my-5"
                        :text="'Guardar'"
                        :loading="loading"
                        @click="handleOnUpdate"
                        :disabled="!currentStudentDiagnosis?.recording_url"
                    />
                </v-col>

                <!-- Cards -->
                <v-col cols="12" md="5" class="my-0 pa-0">
                    <v-col cols="12" class="my-0 mb-3 pa-0">
                        <RecordedAudio
                            :audioURL="studentEvaluation?.recording_url"
                            :lecture-time="studentEvaluation?.audio_duration"
                            :icon="isResults ? 'mdi-check-circle' : null"
                            iconColor="green"
                            :isOpen="!isResults"
                            :loading="loading"
                            @on-audio-ready="onVoiceRecordFinish"
                            class="ma-0 pa-0"
                        />
                    </v-col>

                    <v-col cols="12" class="ma-0 mb-3 pa-0">
                        <DiagnosisErrors
                            :errors-data="studentDiagnosisErrors || []"
                            :icon="isResults ? 'mdi-check-circle' : null"
                            iconColor="green"
                        />
                    </v-col>

                    <v-col cols="12" class="ma-0 pa-0">
                        <ReadingQualityPanel
                            :isOpen="false"
                            :icon="
                                isResults
                                    ? studentEvaluation?.reading_category
                                        ? 'mdi-check-circle'
                                        : 'mdi-minus-circle'
                                    : null
                            "
                            :iconColor="studentEvaluation?.reading_category ? 'green' : 'grey'"
                            :reading-category="studentEvaluationReadingCategory"
                            @on-select-category="handleReadingCategory"
                            :loading="loading"
                        />
                    </v-col>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import SimpleButton from '@/components/core/buttons/simpleButton/index.vue'
import SimpleSelect from '@/components/core/forms/selects/simpleSelect/index.vue'
import StudentInformationPanel from '../shared/panels/StudentInformationPanel/index.vue'
import EvaluatePanel from '../shared/panels/EvaluatePanel/index.vue'
import RecordedAudio from '../shared/panels/recordedAudio/index.vue'
import ReadingQualityPanel from '../shared/panels/readingQuality/index.vue'
import DiagnosisErrors from '../shared/panels/diagnosisErrors/index.vue'

export default {
    name: 'StudentTextEvaluation',
    components: {
        SimpleButton,
        SimpleSelect,
        StudentInformationPanel,
        EvaluatePanel,
        RecordedAudio,
        ReadingQualityPanel,
        DiagnosisErrors,
    },
    data() {
        return {
            currentCourse: 0,
            courseOptions: [],
            currentEvaluationState: 'ALL',
            evaluationID: null,
            loading: false,
            updateEvaluation: false,
            successUpdate: false,
            selectedEvaluation: undefined,
            assignedEvaluations: [],
            wordSelected: null,
            wordSelectedError: null,
            lastWordSelected: null,
            studentID: undefined,
            evaluationID: undefined,
            defaultStudentDiagnosis: {
                errors: [],
                fluency_level: null,
                reading_category: null,
                evaluated_words: [],
                words_per_minute: null,
                correct_words_per_minute: null,
            },
            currentStudentDiagnosis: {
                errors: [],
                fluency_level: null,
                reading_category: null,
                evaluated_words: [],
                words_per_minute: null,
                correct_words_per_minute: null,
            },
            currentEvaluationText: undefined,
            currentReadingCategory: null,
            originalEvaluationResult: {},
            originalEvaluationLastWord: null,
            // studentDiagnosisErrors: []
        }
    },
    computed: {
        ...mapState({
            errorsType: (state) => state.fluency.errorsType,
            evaluationTexts: (state) => state.fluency.evaluationsTexts,
            currentEvaluation: (state) => state.fluency.currentAssignedEvaluationDetail,
            // assignedEvaluations: (state) => state.diagnoses.assignedEvaluations,
            userID: (state) => state.userData.user.id,
        }),
        ...mapGetters({
            evaluationDetail: 'diagnoses/currentDiagnostic',
        }),
        showRegsiterWordPanel() {
            return this.wordSelected !== null
        },
        assignedEvaluationsHeaders() {
            return [
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.assignedText'),
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.assignedTo'),
                    align: 'center',
                    sortable: false,
                    value: 'course_name',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.progress'),
                    align: 'center',
                    sortable: false,
                    value: 'status',
                    width: '150px',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.startDate'),
                    align: 'center',
                    sortable: false,
                    value: 'created_at',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.actions'),
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
            ]
        },
        assignedEvaluationsItems() {
            let filteredEvaluations = this.assignedEvaluations

            if (this.currentCourse !== 0) {
                filteredEvaluations = filteredEvaluations.filter(
                    (e) => e.course_id === this.currentCourse
                )
            }

            if (this.currentEvaluationState !== 'ALL') {
                filteredEvaluations = filteredEvaluations.filter(
                    (e) => e.status === this.currentEvaluationState
                )
            }

            return filteredEvaluations
        },
        studentListHeaders() {
            return [
                {
                    text: 'Student',
                    align: 'start',
                    sortable: false,
                    value: 'student_name',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.progress'),
                    align: 'center',
                    sortable: false,
                    value: 'evaluation_state',
                    width: '150px',
                },
                {
                    text: this.$t('evaluate.tables.assignedEvaluations.labels.actions'),
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
            ]
        },
        studentList() {
            return this.currentEvaluation?.students || []
        },
        textSplittedWords() {
            return this.currentText?.splitted_words || []
        },
        lastWordFromAudio() {
            if (!this.studentEvaluation) return false
            return this.studentEvaluation?.evaluated_words?.find((word) => word.last_word === true)
        },
        currentText() {
            if (!this.currentEvaluation) return undefined
            return this.evaluationTexts.find((text) => text.id === this.currentEvaluation?.text_id)
        },
        studentEvaluation() {
            if (!this.currentEvaluation) return this.currentStudentDiagnosis

            const student = this.currentEvaluation.students.find(
                (student) => student.id === this.studentID
            )
            if (student?.diagnosis != null && student?.diagnosis != undefined) {
                this.currentStudentDiagnosis = {
                    ...student.diagnosis,
                    reading_category: student.diagnosis.reading_category || null,
                    evaluated_words: student.diagnosis.evaluated_words || [],
                    errors: student.diagnosis.errors || [],
                }

                this.currentReadingCategory = student.diagnosis.reading_category
                return this.currentStudentDiagnosis
            } else {
                this.currentStudentDiagnosis = this.defaultStudentDiagnosis
            }
            return this.currentStudentDiagnosis
        },
        studentDiagnosisErrors() {
            if (!this.studentEvaluation) {
                this.studentEvaluation.errors = []
            }
            return this.studentEvaluation?.errors
        },
        studentEvaluationReadingCategory() {
            return this.studentEvaluation?.reading_category
        },
        isResults() {
            return this.studentEvaluation?.recording_url
        },
        isEvalutionWithChanges() {
            const lastWordChanged =
                this.originalEvaluationLastWord?.position !== this.lastWordFromAudio?.position

            if (lastWordChanged) return true

            const errorsChanged = this.compareArraysOfObjects(
                this.studentDiagnosisErrors,
                this.originalEvaluationResult.errors || []
            )
            if (errorsChanged) return true

            return this.compareObjects(this.currentStudentDiagnosis, this.originalEvaluationResult)
        },
    },
    methods: {
        ...mapActions('fluency', [
            'getDiagnosisDetail',
            'getEvaluationTexts',
            'evaluate',
            'updateDiagnosis',
        ]),
        handleWordSelected(word, index, ev) {
            ev.preventDefault()
            if (this.wordSelected && this.wordSelected.index === index) {
                this.wordSelected = null
                this.wordSelectedError = null
                return
            }

            if (this.studentEvaluation?.evaluated_words.length === index) {
                this.wordSelected = null
                this.wordSelectedError = null
                return
            }

            const error = this.isWordError(index)
            const textWordData = this.studentEvaluation?.evaluated_words.find(
                (word) => word.position === index
            )
            this.wordSelected = {
                id: index,
                word,
                index,
                errorType: error,
                error: this.getErrorData(error?.category || null),
                isLastWord: textWordData?.last_word,
            }
            this.wordSelectedError = this.isWordError(index)?.category || null
        },
        closeWordSelected(ev) {
            if (this.wordSelected) {
                this.wordSelected = null
                this.wordSelectedError = null
            }
        },
        isWordError(index) {
            return this.studentDiagnosisErrors.find((error) => error.position === index)
        },
        isLastWord() {
            return this.wordSelected?.isLastWord
        },
        addError() {
            if (!this.wordSelectedError) return
            this.studentDiagnosisErrors.push({
                ...this.wordSelected,
                position: this.wordSelected.index,
                category: this.wordSelectedError,
            })
        },
        removeError() {
            if (!this.wordSelected) return

            this.studentEvaluation.errors = this.studentDiagnosisErrors.filter(
                (error) => error.position !== this.wordSelected.index
            )
        },
        getErrorData(category) {
            if (!category)
                return {
                    id: 0,
                    errorType: null,
                }
            return this.errorsType.find((err) => err.errorType === category.toUpperCase())
        },
        handleGoBackToAssignedEvaluations() {
            this.$router.push(`/teacher/evaluations/evaluate/${this.evaluationID}`)
        },
        getErrorTypeColor(errorType) {
            switch (errorType) {
                case 'CORRECT':
                    return 'grey'
                case 'INSERTION':
                    return 'rgb(255, 230, 224)'
                case 'SUBSTITUTION':
                    return 'rgb(254, 223, 239)'
                case 'OMISSION':
                    return 'rgb(238, 210, 248)'
                case 'REPETITION':
                    return 'rgb(207, 232, 250)'
                case 'DICTION_ERROR':
                    return 'rgb(214, 219, 239)'
            }
        },
        getCheckboxErrorTypeColor(errorType) {
            switch (errorType) {
                case 'INSERTION':
                    return '#EBA696'
                case 'SUBSTITUTION':
                    return '#E179B8'
                case 'OMISSION':
                    return '#C86FE3'
                case 'REPETITION':
                    return '#65C2FD'
                case 'DICTION_ERROR':
                    return '#828DF6'
            }
        },
        getAudioDuration(blob) {
            return new Promise((resolve, reject) => {
                const audio = new Audio()
                const url = URL.createObjectURL(blob)

                audio.onloadeddata = () => {
                    if (audio.duration === Infinity) {
                        // Set the audio current time to a value to force loading the audio and calculating the duration
                        audio.currentTime = Number.MAX_SAFE_INTEGER
                        audio.ontimeupdate = () => {
                            audio.ontimeupdate = null // Clean up the event handler
                            resolve(audio.duration)
                            audio.currentTime = 0 // Reset the time
                        }
                    } else {
                        resolve(audio.duration)
                    }
                }

                audio.onerror = () => {
                    reject(new Error('Failed to load the audio'))
                }

                audio.src = url
            })
        },
        async onVoiceRecordFinish(audioFile) {
            this.loading = true
            try {
                this.closeWordSelected()
                const audioDuration = await this.getAudioDuration(audioFile)
                await this.evaluate({
                    evaluationId: this.evaluationID,
                    studentID: this.studentID,
                    audioFile,
                    audioDuration,
                })
                this.originalEvaluationResult = Object.assign(
                    {},
                    {
                        ...this.studentEvaluation,
                        errors: [...this.studentEvaluation.errors],
                        evaluated_words: [...this.studentEvaluation.evaluated_words],
                    }
                )
                this.originalEvaluationLastWord = Object.assign(
                    {},
                    this.studentEvaluation.evaluated_words.find((word) => word.last_word === true)
                )
                this.$modal.success('Evaluación guardada correctamente.')
            } catch (error) {
                console.error(error)
                this.$modal.error(error)
            } finally {
                this.loading = false
            }
        },
        handleReadingCategory(category) {
            this.currentReadingCategory = category
            this.studentEvaluation.reading_category = category
        },
        handleStudentChange(studentID) {
            this.studentID = studentID
            if (Number(this.$route.params.studentId) !== studentID) {
                this.$router.push(
                    `/teacher/evaluations/evaluate/${this.evaluationID}/student/${studentID}`
                )
            }
        },
        async handleOnUpdate() {
            this.loading = true
            try {
                this.closeWordSelected()
                const results = await this.updateDiagnosis({ diagnosis: this.studentEvaluation })
                this.currentStudentDiagnosis.word_per_minute = results.word_per_minute
                this.currentStudentDiagnosis.correct_words_per_minute =
                    results.correct_words_per_minute
                this.currentStudentDiagnosis.reading_category = results.reading_category
                this.currentStudentDiagnosis.fluency_level = results.fluency_level
                this.originalEvaluationResult = Object.assign(
                    {},
                    {
                        ...this.studentEvaluation,
                        errors: [...this.studentEvaluation.errors],
                        evaluated_words: [...this.studentEvaluation.evaluated_words],
                    }
                )
                this.originalEvaluationLastWord = Object.assign(
                    {},
                    this.studentEvaluation.evaluated_words.find((word) => word.last_word === true)
                )
                this.$modal.success('Evaluación actualizada correctamente.')
            } catch (error) {
                console.error(error)
                this.$modal.error(error)
            } finally {
                this.loading = false
            }
        },
        compareObjects(obj1, obj2) {
            // Get the keys of both objects
            const keys1 = Object.keys(obj1)
            const keys2 = Object.keys(obj2)

            // Check for different number of keys
            if (keys1.length !== keys2.length) {
                return true
            }

            // Iterate over all keys in the first object
            for (const key of keys1) {
                const val1 = obj1[key]
                const val2 = obj2[key]

                // Check if the second object has the key
                if (!(key in obj2)) {
                    return true
                }

                // Check if values are both objects for a deep comparison
                if (
                    typeof val1 === 'object' &&
                    typeof val2 === 'object' &&
                    val1 !== null &&
                    val2 !== null
                ) {
                    if (this.compareObjects(val1, val2)) {
                        return true
                    }
                } else if (val1 !== val2) {
                    // Compare values directly
                    return true
                }
            }

            // If no differences are found
            return false
        },
        compareArraysOfObjects(array1, array2) {
            if (array1.length !== array2.length) {
                return true // Arrays of different lengths are automatically different
            }

            // Check every object in array1 to find an equivalent in array2
            const unmatchedInArray2 = new Set(array2)

            for (const obj1 of array1) {
                let foundMatch = false

                for (const obj2 of unmatchedInArray2) {
                    if (!this.compareObjects(obj1, obj2)) {
                        unmatchedInArray2.delete(obj2)
                        foundMatch = true
                        break
                    }
                }

                if (!foundMatch) {
                    return true // No matching object found for obj1 in array2
                }
            }

            // Check if there are unmatched objects left in array2
            return unmatchedInArray2.size !== 0
        },
    },
    watch: {
        $route(to) {
            if (to.params.studentId && this.studentID !== to.params.studentId) {
                this.studentID = Number(to.params.studentId)
            }
        },
        wordSelectedError(category) {
            if (!this.wordSelected && !category) {
                this.removeError()
                return
            }

            const possibleError = this.studentDiagnosisErrors.find(
                (error) => error.position === this.wordSelected.index
            )

            if (!possibleError) {
                this.addError()
                return
            }

            if (possibleError) {
                if (category === null) {
                    this.removeError()
                } else if (category !== possibleError.category) {
                    this.removeError()
                    this.addError()
                }
            }
        },
        lastWordFromAudio: {
            handler(lastWord) {
                if (this.wordSelected) {
                    const evaluatedWord = this.studentEvaluation.evaluated_words.find(
                        (evaluated) => evaluated.position == lastWord.position
                    )
                    this.lastWordFromAudio.category = evaluatedWord?.category
                    this.lastWordFromAudio.correct = evaluatedWord?.correct
                    this.lastWordFromAudio.correctWord = evaluatedWord?.correctWord
                    this.lastWordFromAudio.position = evaluatedWord?.position
                    this.lastWordFromAudio.textWord = evaluatedWord?.word
                    this.lastWordFromAudio.last_word = lastWord?.position ? true : false
                    this.lastWordFromAudio.transcriptionWord = evaluatedWord?.transcriptionWord
                    // this.handleOnUpdate()
                }
            },
            deep: true,
        },
        currentEvaluation() {
            if (this.currentEvaluation) {
                this.originalEvaluationResult = Object.assign(
                    {},
                    {
                        ...this.studentEvaluation,
                        errors: [...this.studentEvaluation.errors],
                        evaluated_words: [...this.studentEvaluation.evaluated_words],
                    }
                )
                this.originalEvaluationLastWord = Object.assign(
                    {},
                    this.studentEvaluation.evaluated_words.find((word) => word.last_word === true)
                )
            }
        },
        studentEvaluation: {
            handler() {
                if (this.studentEvaluation?.id != this.originalEvaluationResult?.id) {
                    this.originalEvaluationResult = Object.assign(
                        {},
                        {
                            ...this.studentEvaluation,
                            errors: [...this.studentEvaluation.errors],
                            evaluated_words: [...this.studentEvaluation.evaluated_words],
                        }
                    )
                    this.originalEvaluationLastWord = Object.assign(
                        {},
                        this.studentEvaluation.evaluated_words.find(
                            (word) => word.last_word === true
                        )
                    )
                }
            },
            deep: true,
            immediate: true,
        },
    },
    async beforeMount() {
        this.loading = true
        if (!this.evaluationTexts) {
            await this.getEvaluationTexts()
        }
        this.studentID = Number(this.$route.params.studentId)
        this.evaluationID = Number(this.$route.params.assignmentId)
        this.loading = false
    },
}
</script>

<style>
.mdi-checkbox-blank-outline::before {
    content: '' !important;
}

.v-input--selection-controls__input .v-icon .mdi-checkbox-blank-outline::before {
    content: '' !important;
}

.bee-checkbox-test {
    position: absolute;
    left: -27px;
    top: 3px;
    width: 14px;
    height: 14px;
    z-index: -1;
    border-radius: 2px;
}
.bee-text-word {
    position: relative !important;
    cursor: pointer !important;
}
.bee-text-word--selected,
.bee-text-word:hover {
    background-color: #f5f5f5;
    border-radius: 12px;
    text-decoration: underline;
}
.bee-register-word-panel {
    width: auto;
    min-width: 210px;
    position: absolute;
    top: -100%;
    left: 100%;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    padding: 10px;
    z-index: 100;
}

.bee-register-word-panel__close-button {
    top: 6px;
    right: 6px;
    position: absolute;
}
</style>
