<template>
    <v-card class="w-100 pa-3 mb-5" color="white" elevation="0" rounded="lg">
        <v-col cols="12">
            <v-row
                class="w-100 d-flex flex-column flex-md-row pa-0 ma-0"
                align-content="center"
                justify="space-between"
            >
                <div class="d-flex flex-row flex-nowrap align-center text-center">
                    <h2 class="mr-2">Resultados</h2>
                    <v-chip v-if="!isDiagnosisFullEvaluated" label small class="ma-0">
                        <v-icon small class="mr-2"> mdi-minus-box-multiple </v-icon>
                        {{ $t('evaluate.evaluations.states.inProgress') }}
                    </v-chip>
                    <v-chip
                        v-else
                        label
                        small
                        class="ma-0 green--text darken-5"
                        color="green accent-1"
                    >
                        <v-icon small class="mr-2" color="green">
                            mdi-checkbox-multiple-marked
                        </v-icon>
                        {{ $t('evaluate.evaluations.states.completed') }}
                    </v-chip>
                </div>

                <div class="px-6 py-3 pr-0 d-flex align-center justify-content-center text-center">
                    <v-btn
                        fab
                        x-small
                        elevation="0"
                        outlined
                        color="grey"
                        @click="handlePrevStudent"
                        :disabled="!isPrevStudentAvailable"
                    >
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <div class="mx-2">
                        <SimpleSelect
                            :items="students"
                            :value="studentIdSelected"
                            default-item-name-field="fullName"
                            :model="currentStudentSelectedID"
                            @new-value-selected="id => (currentStudentSelectedID = id)"
                            :label="$t('diagnoses.diagnosis.student')"
                        />
                    </div>
                    <v-btn
                        fab
                        x-small
                        elevation="0"
                        outlined
                        color="grey"
                        @click="handleNextStudent"
                        :disabled="!isNextStudentAvailable"
                    >
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
            </v-row>

            <BasicTable :headers="getDiagnosisResultsHeaders" :items="[getDiagnosisResultsValues]" />
        </v-col>
        <v-divider></v-divider>
    </v-card>
</template>

<script>
import SimpleSelect from '@/components/core/forms/selects/simpleSelect/index.vue'
import BasicTable from '@/components/core/basicTable/index.vue'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'EvaluatePanel',
    components: {
        BasicTable,
        SimpleSelect,
    },
    props: {
        diagnosisData: {
            required: true,
        },
        readingCategory: {
            type: String,
            required: false,
            default: null,
        }
    },
    data() {
        return {
            currentStudentSelectedID: undefined,
            assignmentID: null,
        }
    },
    computed: {
        ...mapState({
            currentEvaluation: (state) => state.fluency.currentAssignedEvaluationDetail,
            userID: (state) => state.userData.user.id,
            texts: (state) => state.fluency.evaluationTexts,
        }),
        getDiagnosisResultsHeaders() {
            return [
                {
                    text: 'Palabras por minuto',
                    value: 'word_per_minute',
                    align: 'center',
                    sortable: true,
                },
                {
                    text: this.$t('evaluate.tables.diagnosisResults.labels.correctWordsPerMinute'),
                    value: 'correct_words_per_minute',
                    align: 'center',
                    sortable: true,
                },
                {
                    text: this.$t('evaluate.tables.diagnosisResults.labels.readingSpeed'),
                    value: 'fluency_level',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: this.$t('evaluate.tables.diagnosisResults.labels.readingQuality'),
                    value: 'reading_category',
                    align: 'center',
                    sortable: false,
                },
            ]
        },
        getDiagnosisResultsValues() {
            return {
                    word_per_minute: this.currentStudentResults?.word_per_minute,
                    correct_words_per_minute: this.currentStudentResults?.word_per_minute - this.currentStudentResults?.errors.length,
                    fluency_level: this.currentStudentResults?.fluency_level,
                    reading_category: this.readingCategory,
                }
        },
        currentStudentResults() {
            return this.diagnosisData
        },
        students() {
            return this.currentEvaluation?.students.map(stu => ({...stu, fullName: `${stu.name} ${stu.lastname}`.toUpperCase()})) || []
        },
        isDiagnosisFullEvaluated() {
            if (
                !this.currentStudentResults?.fluency_level ||
                !this.currentStudentResults?.reading_category ||
                !this.currentStudentResults?.word_per_minute
            ) {
                return false
            }

            return true
        },
        currentStudentIndex() {
            return this.students.findIndex(
                (student) => student.id === this.studentIdSelected
            )
        },
        studentIdSelected() {
            return this.currentStudentSelectedID
        },
        isNextStudentAvailable() {
            const nextStudentIndex = this.currentStudentIndex + 1

            if (nextStudentIndex < this.students.length) {
                return true
            }

            return false
        },
        isPrevStudentAvailable() {
            const prevStudentIndex = this.currentStudentIndex - 1

            if (prevStudentIndex >= 0) {
                return true
            }

            return false
        },
    },
    methods: {
        ...mapActions('fluency', ['getDiagnosisDetail', 'getEvaluationTexts']),
        handleNextStudent() {
            if(this.isNextStudentAvailable) {
                const nextStudentIndex = this.currentStudentIndex + 1
                this.currentStudentSelectedID = this.students[nextStudentIndex].id
            }
        },
        handlePrevStudent() {
            if(this.isPrevStudentAvailable) {
                const prevStudentIndex = this.currentStudentIndex - 1
                this.currentStudentSelectedID = this.students[prevStudentIndex].id
            }
        }
    },
    watch: {
        $route(to) {
            if(to.params.studentId !== this.currentStudentSelectedID) {
                this.currentStudentSelectedID = Number(to.params.studentId)
            }
        },
        currentStudentSelectedID(val) {
            this.$emit('on-student-change', this.currentStudentSelectedID)
        },
    },
    async beforeMount() {
        this.assignmentID = this.$route.params.assignmentId

        if (!this.currentEvaluation) {
            await this.getDiagnosisDetail({
                diagnosisID: this.assignmentID,
                teacherID: this.userID,
            })
        }

        await this.getEvaluationTexts()
        this.currentStudentSelectedID = Number(this.$route.params.studentId)
    }
}
</script>
