<template>
     <v-card :color="`${color.card}`" elevation="0" rounded="lg" class="pa-0 ma-0">
        <v-row align-content="center" justify="space-between" class="ma-0 pa-2 px-6">
            <p class="black--text body-2 ma-0 pa-0 font-weight-semibold mt-1">
                {{ errorType }}: <v-icon color="blue-grey" small>mdi-information</v-icon>
            </p>

            <v-chip label class="pa-3 px-6" :color="`${color.chip}`">
                <span :style="`color: ${color.text};`" class="font-weight-bold">
                    {{ amount }}
                </span>
            </v-chip>
        </v-row>
    </v-card>
</template>
<script>
export default {
    name: 'errorCardBanner',
    props: {
        color: {
            type: String,
            default: 'orange'
        },
        amount: {
            type: Number,
            default: 0
        },
        errorType: {
            type: String,
            default: 'General'
        },
    },
}
</script>