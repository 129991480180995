<template>
    <ExpansionPanelContainer class="py-0" :panelsModel="isOpen ? [0] : []">
        <ExpansionPanel
            :title="$t('evaluate.titles.recording')"
            :title-icon="icon"
            :iconColor="iconColor"
        >
            <section class="py-3">
                <VoiceRecording
                    :voiceRecordURL="audioURL"
                    :isRecorded="isRecorded"
                    @recording-finished="handleRecordFinished"
                    @on-audio-ready="handleAudio"
                    @on-reset-record="handleResetRecord"
                />
            </section>
            <p v-bind="attrs" v-on="on" class="body-2" v-if="lectureTime">
                <span class="font-weight-bold">{{ $t('evaluate.titles.lectureTime') }}:</span>
                {{ lectureTime.toFixed(0) }} {{ $t('evaluate.utils.seconds') }}
            </p>
        </ExpansionPanel>
    </ExpansionPanelContainer>
</template>

<script>
import ExpansionPanelContainer from '@/components/core/expansionPanels/container/index.vue'
import ExpansionPanel from '@/components/core/expansionPanels/panel/index.vue'
import VoiceRecording from '@/components/core/voiceRecording/index.vue'
import SimpleButton from '@/components/core/buttons/simpleButton/index.vue'

export default {
    name: 'RecordedAudioPanel',
    components: {
        ExpansionPanelContainer,
        ExpansionPanel,
        VoiceRecording,
        SimpleButton,
    },
    props: {
        audioURL: {
            type: String,
            required: false,
        },
        isOpen: {
            type: Boolean,
            default: false,
            required: false,
        },
        icon: {
            type: String,
            default: null,
            required: false,
        },
        iconColor: {
            type: String,
            default: 'primary',
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        lectureTime: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            seeResultsAvailable: false,
            audioFile: null,
        }
    },
    computed: {
        isRecorded() {
            return this.audioURL ? true : false
        },
    },
    methods: {
        handleAudio(audioBlob) {
            this.audioFile = audioBlob
            this.$emit('on-audio-ready', audioBlob)
        },
        handleRecordFinished(audioFile) {
            this.audioFile = audioFile
            this.seeResultsAvailable = true
        },
        handleResetRecord() {
            this.seeResultsAvailable = false
        },
    },
}
</script>
