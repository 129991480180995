<template>
    <ExpansionPanelContainer class="py-0" :panelsModel="isOpen ? [0] : []">
        <ExpansionPanel
            :title="$t('evaluate.titles.readingQuality')"
            :title-icon="icon"
            :iconColor="iconColor"
        >
            <v-radio-group v-model="currentOption" class="ma-0 pa-0">
                <v-radio
                    v-for="opt in readingQualityOptions"
                    :key="opt.id"
                    :label="$t(`evaluate.evaluations.readingQuality.${opt.name}`)"
                    :value="opt.id"
                    color="deep-purple"
                    class="my-2"
                >
                    <template v-slot:label>
                        <!-- <div class="d-flex align-center">
                            <span>{{ $t(`evaluate.evaluations.readingQuality.${opt.name}`) }}</span>
                            <v-icon class="mx-2" small color="deep-purple lighten-4"
                                >mdi-information</v-icon
                            >
                        </div> -->
                    </template>
                </v-radio>
            </v-radio-group>
        </ExpansionPanel>
    </ExpansionPanelContainer>
</template>

<script>
import ExpansionPanelContainer from '@/components/core/expansionPanels/container/index.vue'
import ExpansionPanel from '@/components/core/expansionPanels/panel/index.vue'
import SimpleButton from '@/components/core/buttons/simpleButton/index.vue'

export default {
    name: 'ReadingQuality',
    components: {
        ExpansionPanelContainer,
        ExpansionPanel,
        SimpleButton,
    },
    props: {
        readingCategory: {
            type: String,
            required: false,
            default: null,
        },
        isOpen: {
            type: Boolean,
            default: false,
            required: false,
        },
        icon: {
            type: String,
            default: null,
            required: false,
        },
        iconColor: {
            type: String,
            default: 'primary',
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            currentOption: null,
        }
    },
    computed: {
        // ...mapGetters({
        //     readingQualityOptions: 'diagnoses/getReadingQualityOptions',
        // }),
        readingQualityOptions() {
            return [
                {
                    id: 1,
                    name: 'NO_READER',
                },
                {
                    id: 2,
                    name: 'SYLLABIC_READING',
                },
                {
                    id: 3,
                    name: 'WORD_BY_WORD_READING',
                },
                {
                    id: 4,
                    name: 'SHORT_UNITS_READING',
                },
                {
                    id: 5,
                    name: 'FLUENT_READING',
                },
            ]
        },
    },
    watch: {
        currentOption: {
            immediate: true,
            handler(newVal) {
                const readingQualityOption = this.readingQualityOptions.find(
                    (opt) => opt.id === this.currentOption
                )
                if (typeof newVal === 'string') {
                    const option = this.readingQualityOptions.find((opt) => opt.name === newVal)
                    this.currentOption = option?.id || null
                    this.$emit('on-select-category', readingQualityOption?.name)
                    return
                }
                this.currentOption = newVal
                this.$emit('on-select-category', readingQualityOption?.name)
            },
        },
        readingCategory(val) {
            this.currentOption = this.readingQualityOptions.find((opt) => opt.name === val)?.id
        },
    },
    beforeMount() {
        if (!this.readingCategory) return
        this.currentOption = this.readingQualityOptions.find((opt) => opt.name === this.readingCategory)?.id

    },
}
</script>
