<template>
    <ExpansionPanelContainer :panelsModel="[0]">
        <ExpansionPanel :title="$t('diagnoses.titles.studentInformation')">
            <BasicTable :headers="getStudentInformationHeaders" :items="students" />
        </ExpansionPanel>
    </ExpansionPanelContainer>
</template>

<script>
import ExpansionPanelContainer from '@/components/core/expansionPanels/container/index.vue'
import ExpansionPanel from '@/components/core/expansionPanels/panel/index.vue'
import BasicTable from '@/components/core/basicTable/index.vue'

export default {
    name: 'StudentInformation',
    props: {
        students: {
            type: Array,
            default: () => [],
            required: false,
        }
    },
    components: {
        ExpansionPanelContainer,
        ExpansionPanel,
        BasicTable
    },
    computed: {
        getStudentInformationHeaders() {
            return [
                {
                    text: this.$t('diagnoses.tables.studentsList.labels.firstName'),
                    value: 'name',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: this.$t('diagnoses.tables.studentsList.labels.lastName'),
                    value: 'lastname',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: this.$t('diagnoses.tables.studentsList.labels.course'),
                    value: 'course_name',
                    align: 'center',
                    sortable: false,
                },
            ]
        }
    }
}
</script>